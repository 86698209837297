import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import { Link } from 'gatsby'
const item1 = {image: "/img/V2Ipaper1.png"}


export const Research2PageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    
    
    <section className="section section--gradient" style ={{padding:'0rem 1.5rem'}}>
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section" style={{padding:'0.0rem'}}>
            <div
          className="full-width-image-container margin-top-0"
          style={{
            backgroundImage: `url('/img/V2I.jpg')`,
          }}
        >
          <h1
            className="has-text-weight-bold is-size-1"
            style={{
              boxShadow: '0.5rem 0 0 #f40, -0.5rem 0 0 #f40',
              backgroundColor: '#f40',
              color: 'white',
              padding: '1rem',
            }}
          >
            V2V & V2I connectivity related Research
          </h1>
        </div>
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h2>
              <div className="tile is-vertical">
                  <div className="tile">
                    <div className="tile is-parent is-vertical">
                      <article className="tile is-child" >
                      
                        <PreviewCompatibleImage imageInfo={item1}/>
                                        
                       
                      </article>
                    </div>
                    <div className="tile is-parent">
                      <article className="tile is-child" style={{color:'#000000'}}>
                      <p style={{color:'#ee6d2a'}} style={{padding:'0.5rem'}}>
                        <Link className="has-text-primary content is-size-5" to="https://arxiv.org/abs/2008.04351">
                        Vehicle Connectivity and Automation: A Sibling Relationship
                        </Link>
                        </p>
                      <p style={{padding:'0.5rem'}}>
                       The paper first reviews the technological concepts of systems automation and systems connectivity, and how they prospectively, from an individual and collective perspective, impact road transportation efficiency and safety. The paper also discusses the separate and common benefits of connectivity and automation, and their possible holistic effects in terms of these benefits where they overlap.
                       The paper suggests that at the current time, the sibling relationship seems to be lopsided: vehicle connectivity has immense potential to enhance vehicle automation. 
                       </p>
                      
                      </article>
                    </div>

                  </div>

                  
                </div>
                

                
                

            </div>
          </div>
        </div>
      </div>
    </section>
  )
  
}

Research2PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const Research2Page = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <Research2PageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

Research2Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Research2Page

export const research2PageQuery = graphql`
  query Research2Page($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
